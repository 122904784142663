import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Assessments from "../views/MyAssessments.vue";
import { getAuth } from "firebase/auth";
import mixpanel from "mixpanel-browser";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Default",
    component: Assessments,
    meta: {
      navbar: true,
      navbarName: "assessments",
      auth: true,
      title: "My Assessments | My Culture",
    },
  },
  {
    path: "/:code/:value?",
    name: "RedirectPage",
    component: () =>
      import(
        /* webpackChunkName: "redirect-page" */ "../views/RedirectPage.vue"
      ),
    meta: {
      navbar: false,
      title: "Redirect Page | My Culture",
    },
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Login/Login.vue"),
    meta: {
      navbar: false,
      title: "Login | My Culture",
    },
  },
  {
    path: "/th/login",
    name: "Login_th",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Login/Login.vue"),
    meta: {
      navbar: false,
      title: "เข้าสู่ระบบ | My Culture",
      lang: "TH",
    },
  },
  {
    path: "/signup",
    name: "Signup",
    component: () =>
      import(/* webpackChunkName: "signup" */ "../views/Login/Signup.vue"),
    meta: {
      navbar: false,
      title: "Sign Up | My Culture",
    },
  },
  {
    path: "/th/signup",
    name: "Signup_th",
    component: () =>
      import(/* webpackChunkName: "signup" */ "../views/Login/Signup.vue"),
    meta: {
      navbar: false,
      title: "สร้างบัญชีใหม่ | My Culture",
      lang: "TH",
    },
  },
  {
    path: "/signup/verify",
    name: "SignupVerify",
    component: () =>
      import(/* webpackChunkName: "verify" */ "../views/Login/Pincode.vue"),
    meta: {
      navbar: false,
      title: "Verify PIN | My Culture",
    },
  },
  {
    path: "/signup/info",
    name: "SignupInfo",
    component: () =>
      import(/* webpackChunkName: "signup-info" */ "../views/Login/Info.vue"),
    meta: {
      navbar: false,
      title: "Your Info | My Culture",
    },
  },
  {
    path: "/signup/branding",
    name: "SignupBranding",
    component: () =>
      import(
        /* webpackChunkName: "signup-branding" */ "../views/Login/Branding.vue"
      ),
    meta: {
      navbar: false,
      title: "Your Branding | My Culture",
    },
  },
  {
    path: "/confirm-canditate",
    name: "ConfirmCanditate",
    component: () =>
      import(
        /* webpackChunkName: "confirm-canditate" */ "../views/ConfirmCandidate.vue"
      ),
    meta: {
      navbar: false,
      title: "Confirm your profile | My Culture",
    },
  },
  {
    path: "/pincode",
    name: "Pincode",
    component: () =>
      import(/* webpackChunkName: "signup" */ "../views/Login/Pincode.vue"),
    meta: {
      navbar: false,
      title: "Pincode | My Culture",
    },
  },
  {
    path: "/assessments",
    name: "Assessments",
    component: Assessments,
    meta: {
      navbar: true,
      navbarName: "assessments",
      auth: true,
      title: "My Assessments | My Culture",
    },
  },
  {
    path: "/assessments/assessments",
    name: "AssessmentsAssessmentsDetails",
    component: () =>
      import(
        /* webpackChunkName: "AssessmentsDetails" */ "../views/Assessments/AssessmentsDetails.vue"
      ),
    meta: {
      navbar: false,
      navbarName: "assessments",
      auth: true,
      title: "My Assessments | My Culture",
    },
  },
  {
    path: "/assessments/candidate",
    name: "AssessmentsCandidate",
    component: () =>
      import(
        /* webpackChunkName: "AssessmentsCandidate" */ "../views/Assessments/Candidate.vue"
      ),
    meta: {
      navbar: false,
      navbarName: "assessments",
      auth: true,
      title: "My Culture",
    },
  },
  {
    path: "/assessments/create/:step",
    name: "AssessmentsCreate",
    component: () =>
      import(
        /* webpackChunkName: "assessments-create" */ "../views/Assessments/Create.vue"
      ),
    meta: {
      navbar: false,
      navbarName: "assessments",
      auth: true,
      title: "Create a New Assessment | My Culture",
    },
  },
  {
    path: "/assessment/create/set-up/values-and-traits",
    name: "AssessmentsCreateSetUpTaluesAndTraits",
    component: () =>
      import(
        /* webpackChunkName: "setupValuesAndTraits" */ "../views/Assessments/Create/SetupValues&Traits.vue"
      ),
    meta: {
      navbar: false,
      auth: true,
      title: "Setup Values an Traits | My Culture",
    },
  },
  {
    path: "/assessment/create/set-up/acceptable-behaviors",
    name: "SetupAcceptableBehaviors",
    component: () =>
      import(
        /* webpackChunkName: "SetupAcceptableBehaviors" */ "../views/Assessments/Create/SetupAcceptableBehaviors.vue"
      ),
    meta: {
      navbar: false,
      auth: true,
      title: "Setup Acceptable Behaviors | My Culture",
    },
  },
  {
    path: "/assessment/create/set-up/OAIC-archetypes",
    name: "SetupOAICArchetypes",
    component: () =>
      import(
        /* webpackChunkName: "SetupOAICArchetypes" */ "../views/Assessments/Create/SetupOAICArchetypes.vue"
      ),
    meta: {
      navbar: false,
      auth: true,
      title: "Setup Culture Profile | My Culture",
    },
  },
  //Custom Survey
  {
    path: "/assessment/create/set-up/custom-surveys",
    name: "SetUpCustomSurvey",
    component: () =>
      import(
        /* webpackChunkName: "SetUpCustomSurvey" */ "../views/Assessments/Create/SetupCustomSurveys.vue"
      ),
    meta: {
      navbar: false,
      auth: true,
      title: "Setup Custom Surveys | My Culture",
    },
  },
  {
    path: "/assessment/edit/:routeId",
    name: "AssessmentsEdit",
    component: () =>
      import(
        /* webpackChunkName: "assessments-create" */ "../views/Assessments/Create.vue"
      ),
    meta: {
      navbar: true,
      navbarName: "assessments",
      auth: true,
      title: "Edit an Assessment | My Culture",
    },
  },
  {
    path: "/assessment-test",
    name: "AssessmentTest",
    component: () =>
      import(
        /* webpackChunkName: "assessment-test" */ "../views/AssessmentTest.vue"
      ),
    meta: {
      navbar: false,
      title: "Assessment Test | My Culture",
    },
  },
  {
    path: "/assessment-test/:id/:params",
    name: "AssessmentTestForCandidate",
    component: () =>
      import(
        /* webpackChunkName: "assessment-test" */ "../views/AssessmentTest.vue"
      ),
    meta: {
      navbar: false,
      title: "Assessment Test | My Culture",
    },
  },
  {
    path: "/assessment-test/:code",
    name: "AssessmentTestForCandidateSecure",
    component: () =>
      import(
        /* webpackChunkName: "assessment-test" */ "../views/AssessmentTest.vue"
      ),
    meta: {
      navbar: false,
      title: "Assessment Test | My Culture",
    },
  },
  {
    path: "/respondents",
    name: "Respondents",
    component: () =>
      import(
        /* webpackChunkName: "respondents" */ "../views/MyRespondents.vue"
      ),
    meta: {
      navbar: true,
      navbarName: "respondents",
      auth: true,
      title: "My Respondents | My Culture",
    },
  },
  {
    path: "/account",
    name: "MyAccount",
    component: () =>
      import(/* webpackChunkName: "account" */ "../views/MyAccount.vue"),
    meta: {
      navbar: false,
      auth: true,
      title: "My Account | My Culture",
    },
  },
  {
    path: "/account/my-team/:teamId",
    name: "MyAccountWithTeamId",
    component: () =>
      import(/* webpackChunkName: "account" */ "../views/MyAccount.vue"),
    meta: {
      navbar: false,
      auth: true,
      title: "My Account | My Culture",
    },
  },
  {
    path: "/account/:tab",
    name: "MyAccountWithTab",
    component: () =>
      import(/* webpackChunkName: "account" */ "../views/MyAccount.vue"),
    meta: {
      navbar: false,
      auth: true,
      title: "My Account | My Culture",
    },
  },
  {
    path: "/docs-for-dev",
    name: "DocsForDev",
    component: () =>
      import(/* webpackChunkName: "docs-for-dev" */ "../views/DocsForDev.vue"),
    meta: {
      title: "Docs | My Culture",
    },
  },
  {
    path: "/assessment-results",
    name: "AssessmentResults",
    component: () =>
      import(
        /* webpackChunkName: "assessment-results" */ "../views/AssessmentResults.vue"
      ),
    meta: {
      navbar: false,
      title: "My Culture",
    },
  },
  {
    path: "/th/assessment-results",
    name: "AssessmentResultsTH",
    component: () =>
      import(
        /* webpackChunkName: "assessment-results" */ "../views/AssessmentResults.vue"
      ),
    meta: {
      navbar: false,
      title: "My Culture",
      lang: "TH",
    },
  },
  {
    path: "/en/assessment-results",
    name: "AssessmentResultsEN",
    component: () =>
      import(
        /* webpackChunkName: "assessment-results" */ "../views/AssessmentResults.vue"
      ),
    meta: {
      navbar: false,
      title: "My Culture",
      lang: "EN",
    },
  },
  {
    path: "/core-values-analyzer",
    name: "CoreValuesAnalyzer",
    component: () =>
      import(
        /* webpackChunkName: "core-values-analyzer" */ "../views/CoreValuesAnalyzer.vue"
      ),
    meta: {
      navbar: false,
      title: "Core Values Analyzer | My Culture",
    },
  },
  {
    path: "/en/core-values-analyzer",
    name: "CoreValuesAnalyzerEN",
    component: () =>
      import(
        /* webpackChunkName: "core-values-analyzer" */ "../views/CoreValuesAnalyzer.vue"
      ),
    meta: {
      navbar: false,
      title: "Core Values Analyzer | My Culture",
      lang: "EN",
    },
  },
  {
    path: "/th/core-values-analyzer",
    name: "CoreValuesAnalyzerTH",
    component: () =>
      import(
        /* webpackChunkName: "core-values-analyzer" */ "../views/CoreValuesAnalyzer.vue"
      ),
    meta: {
      navbar: false,
      title: "Core Values Analyzer | My Culture",
      lang: "TH",
    },
  },
  {
    path: "/job-role-generator",
    name: "JobRoleGenerator",
    component: () =>
      import(
        /* webpackChunkName: "job-role-generator1" */ "../views/JobRoleGenerator.vue"
      ),
    meta: {
      navbar: true,
      navbarName: "core-values",
      auth: true,
      title: "Job Role Generator | My Culture",
    },
  },
  {
    path: "/en/job-role-generator",
    name: "JobRoleGeneratorEN",
    component: () =>
      import(
        /* webpackChunkName: "job-role-generator2" */ "../views/JobRoleGenerator.vue"
      ),
    meta: {
      navbar: true,
      navbarName: "core-values",
      auth: true,
      title: "Job Role Generator | My Culture",
      lang: "EN",
    },
  },
  {
    path: "/th/job-role-generator",
    name: "JobRoleGeneratorTH",
    component: () =>
      import(
        /* webpackChunkName: "job-role-generator3" */ "../views/JobRoleGenerator.vue"
      ),
    meta: {
      navbar: true,
      navbarName: "core-values",
      auth: true,
      title: "Job Role Generator | My Culture",
      lang: "TH",
    },
  },
  {
    path: "/job-post-generator",
    name: "JobPostGenerator",
    component: () =>
      import(
        /* webpackChunkName: "job-post-generator1" */ "../views/JobPostGenerator.vue"
      ),
    meta: {
      navbar: true,
      navbarName: "core-values",
      auth: true,
      title: "Job Post Generator | My Culture",
    },
  },
  {
    path: "/en/job-post-generator",
    name: "JobPostGeneratorEN",
    component: () =>
      import(
        /* webpackChunkName: "job-post-generator2" */ "../views/JobPostGenerator.vue"
      ),
    meta: {
      navbar: true,
      navbarName: "core-values",
      auth: true,
      title: "Job Post Generator | My Culture",
      lang: "EN",
    },
  },
  {
    path: "/th/job-post-generator",
    name: "JobPostGeneratorTH",
    component: () =>
      import(
        /* webpackChunkName: "job-post-generator3" */ "../views/JobPostGenerator.vue"
      ),
    meta: {
      navbar: true,
      navbarName: "core-values",
      auth: true,
      title: "Job Post Generator | My Culture",
      lang: "TH",
    },
  },
  {
    path: "/interview-guide",
    name: "InterviewGuide",
    component: () =>
      import(
        /* webpackChunkName: "interview-guide1" */ "../views/InterviewGuide.vue"
      ),
    meta: {
      navbar: true,
      navbarName: "core-values",
      auth: true,
      title: "Interview Guide | My Culture",
    },
  },
  {
    path: "/en/interview-guide",
    name: "InterviewGuideEN",
    component: () =>
      import(
        /* webpackChunkName: "interview-guide2" */ "../views/InterviewGuide.vue"
      ),
    meta: {
      navbar: true,
      navbarName: "core-values",
      auth: true,
      title: "Interview Guide | My Culture",
      lang: "EN",
    },
  },
  {
    path: "/th/interview-guide",
    name: "InterviewGuideTH",
    component: () =>
      import(
        /* webpackChunkName: "interview-guide3" */ "../views/InterviewGuide.vue"
      ),
    meta: {
      navbar: true,
      navbarName: "core-values",
      auth: true,
      title: "Interview Guide | My Culture",
      lang: "TH",
    },
  },
  {
    path: "/career-path-tracker",
    name: "CareerPathTracker",
    component: () =>
      import(
        /* webpackChunkName: "career-path-tracker1" */ "../views/CareerPathTracker.vue"
      ),
    meta: {
      navbar: true,
      navbarName: "core-values",
      auth: true,
      title: "Career Path Tracker | My Culture",
    },
  },
  {
    path: "/en/career-path-tracker",
    name: "CareerPathTrackerEN",
    component: () =>
      import(
        /* webpackChunkName: "career-path-tracker2" */ "../views/CareerPathTracker.vue"
      ),
    meta: {
      navbar: true,
      navbarName: "core-values",
      auth: true,
      title: "Career Path Tracker | My Culture",
      lang: "EN",
    },
  },
  {
    path: "/th/career-path-tracker",
    name: "CareerPathTrackerTH",
    component: () =>
      import(
        /* webpackChunkName: "career-path-tracker3" */ "../views/CareerPathTracker.vue"
      ),
    meta: {
      navbar: true,
      navbarName: "core-values",
      auth: true,
      title: "Career Path Tracker | My Culture",
      lang: "TH",
    },
  },
  {
    path: "/okr-generator",
    name: "OkrGenerator",
    component: () =>
      import(
        /* webpackChunkName: "okr-generator1" */ "../views/OkrGenerator.vue"
      ),
    meta: {
      navbar: true,
      navbarName: "core-values",
      auth: true,
      title: "OKR Generator | My Culture",
    },
  },
  {
    path: "/en/okr-generator",
    name: "OkrGeneratorEN",
    component: () =>
      import(
        /* webpackChunkName: "okr-generator2" */ "../views/OkrGenerator.vue"
      ),
    meta: {
      navbar: true,
      navbarName: "core-values",
      auth: true,
      title: "OKR Generator | My Culture",
      lang: "EN",
    },
  },
  {
    path: "/th/okr-generator",
    name: "OkrGeneratorTH",
    component: () =>
      import(
        /* webpackChunkName: "okr-generator3" */ "../views/OkrGenerator.vue"
      ),
    meta: {
      navbar: true,
      navbarName: "core-values",
      auth: true,
      title: "OKR Generator | My Culture",
      lang: "TH",
    },
  },
  {
    path: "/performance-improvement",
    name: "PerformanceImprovement",
    component: () =>
      import(
        /* webpackChunkName: "okr-generator1" */ "../views/PerformanceImprovement.vue"
      ),
    meta: {
      navbar: true,
      navbarName: "core-values",
      auth: true,
      title: "Performance Improvement | My Culture",
    },
  },
  {
    path: "/en/performance-improvement",
    name: "PerformanceImprovementEN",
    component: () =>
      import(
        /* webpackChunkName: "okr-generator2" */ "../views/PerformanceImprovement.vue"
      ),
    meta: {
      navbar: true,
      navbarName: "core-values",
      auth: true,
      title: "Performance Improvement | My Culture",
      lang: "EN",
    },
  },
  {
    path: "/th/performance-improvement",
    name: "PerformanceImprovementTH",
    component: () =>
      import(
        /* webpackChunkName: "okr-generator3" */ "../views/PerformanceImprovement.vue"
      ),
    meta: {
      navbar: true,
      navbarName: "core-values",
      auth: true,
      title: "Performance Improvement | My Culture",
      lang: "TH",
    },
  },
  {
    path: "/day-plan",
    name: "NumDayPlan",
    component: () =>
      import(
        /* webpackChunkName: "okr-generator1" */ "../views/NumDayPlan.vue"
      ),
    meta: {
      navbar: true,
      navbarName: "core-values",
      auth: true,
      title: "Performance Improvement | My Culture",
    },
  },
  {
    path: "/en/day-plan",
    name: "NumDayPlanEN",
    component: () =>
      import(
        /* webpackChunkName: "okr-generator2" */ "../views/NumDayPlan.vue"
      ),
    meta: {
      navbar: true,
      navbarName: "core-values",
      auth: true,
      title: "Performance Improvement | My Culture",
      lang: "EN",
    },
  },
  {
    path: "/th/day-plan",
    name: "NumDayPlanTH",
    component: () =>
      import(
        /* webpackChunkName: "okr-generator3" */ "../views/NumDayPlan.vue"
      ),
    meta: {
      navbar: true,
      navbarName: "core-values",
      auth: true,
      title: "Performance Improvement | My Culture",
      lang: "TH",
    },
  },
  {
    path: "/core-values",
    name: "CoreValues",
    component: () =>
      import(
        /* webpackChunkName: "my-core-values" */ "../views/MyCoreValues.vue"
      ),
    meta: {
      navbar: true,
      navbarName: "core-values",
      auth: true,
      title: "My Core Values | My Culture",
    },
  },
  // {
  //   path: "/setup",
  //   name: "Setup",
  //   component: () =>
  //     import(/* webpackChunkName: "setup" */ "../views/Setup.vue"),
  //   meta: {
  //     title: "My Culture",
  //   },
  // },
  {
    path: "/404",
    name: "pageNotFound404",
    component: () => import(/* webpackChunkName: "404" */ "../views/404.vue"),
    meta: {
      title: "Page Not Found | My Culture",
    },
  },
  {
    path: "/:pathMatch(.*)*",
    name: "pageNotFound",
    component: () => import(/* webpackChunkName: "404" */ "../views/404.vue"),
    meta: {
      title: "Page Not Found | My Culture",
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const mixpanelEnabled = process.env.VUE_APP_MIXPANEL_ENABLED == "on";
  if (mixpanelEnabled) {
    if (to.meta && to.meta.title) {
      const title = String(to.meta.title || "Untitled");
      const token = String(process.env.VUE_APP_MIXPANEL_TOKEN);
      const debug = process.env.VUE_APP_MIXPANEL_DEBUG == "on";
      if (token) {
        mixpanel.init(token, { debug: debug });
        mixpanel.track(title);
        // console.log(`mixpanel.track(${title})`);
      }
    }
  }
  if (to.matched.some((record) => record.meta.auth)) {
    // because of this project has social login
    // NOT same MY Happily so that can't use fix login
    /// and force to accesss this page
    const userDataEncoded = localStorage.getItem("userData") || "";
    let valid = false;
    try {
      const userData = JSON.parse(atob(userDataEncoded) || "{}");
      if (userData && userData.email) {
        valid = true;
      }
    } catch (e) {
      console.error(e);
    }

    if (!valid) {
      const auth = getAuth();
      localStorage.removeItem("userData");
      auth.signOut();
      if (to.path == "/core-values") {
        next({
          path: "/core-values-analyzer",
          query: to.query,
        });
      } else {
        next({
          path: "/login",
        });
      }
    } else {
      if (to.path == "/") {
        next({
          path: "/assessments",
        });
      } else {
        next();
      }
    }
  } else {
    next();
  }
});

export default router;
